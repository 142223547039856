export const environment = {
    baseUrl: 'https://ezstock-dev-api.zuelligpharma.com/',
    production: false,
    authenticate: {
        authority: 'https://ezstock-dev-sso.zuelligpharma.com',
        redirectUrl: 'https://ezstock-dev.zuelligpharma.com',
        postLogoutRedirectUri: 'https://ezstock-dev.zuelligpharma.com',
        clientId: 'EZSTOCK-LOCAL',
        scope: 'openid profile ezstock',
        responseType: 'id_token token',
        silentRenew: true,
        useRefreshToken: true,
    },
    scandit: {
        licenseKey:
            'AVUkrT3zGkDRGfE9Yxq+FAMRkzfP5t9WL20Pz1hjCAs6AT+C60+J6YoWKw3aXG9bBGeH5IduzIwqUkcaSTL+CyA4g80NfH/48Fd3YwJCClJkLJLLZGbtSwhE1yMIZEtDe3EaJvN/aOzlOgxy6F4E4iQv29D9fxVTnlQ3Wjpss4GMfLAMaG4nbEA3bbvlAPVgejMKfqAawTmeInwuwZGoPOqCy31K8y/6ZK57LjmISWYm4BACG5LidoBCnbSLHLHaje0Vb9vGxKEGouejLZXC9TAI4wcvTTlshFDK/HJyvWViNu2cUXofzMU5gzIv/FkM4ios2U1gIQ2SujHw9s4YowcS60wvaTQT20Zpk7YRa4JABnEBgfCoEONkUOo6U+wQNoK8vTrST/PfB/IUlCbj/tZBiruLNDsT5OsYJxUJwuxNUa/FOUOkRxaZcOqCQEVDq1vQFR0/0NnLn6v+83b1kOF3iWW+FBACBXg02rACQIDIW91NZHbvPYO3xt1CS+BJ6/v+01H7PBH2aIiclqjKj4nQut5ZY6M2AQf23PhpJE6knudd9dy5u455lll4dRqMNOqTA4lSD+wjeU8soQ9mx2WljXTtuhQUegWdnZoJ/c/i3CjTbmOHl/eqD0pyVbnuba2Md3PVeYKi4sgh0dzB+DF5H1ULc6jRhLvMUeK3WWLB6zmp1MNXAUyFeOKmvhsJjsbyQmbnQSzUD1adXSfFOa50kuPyVmxjave/SLYUBn8vOoOyTRDG/utWc5CAix/T69r3GxmUjd+w2yMloBivYH5plbK1D8vDvo7o/b95zyzfprr/ClALO2XbR/1+ZHO9Yd0VTb6If6ecfVBJvtxE2/fXsZm7Tk5NJFP3lkOE94kbXF+6zbzRHL6I',
    },
    appInsights: {
        instrumentationKey: '',
        disableTelemetry: true,
        enableAutoRouteTracking: false,
        roleName: '',
        roleInstance: '',
    },
};
