import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConfigFeatureKey, ConfigState } from './stock-count-config';
import { state } from '@angular/animations';

const PageConfigState = createFeatureSelector<ConfigState>(ConfigFeatureKey);
export class StockCountSelector {
    static readonly binListConfig = createSelector(PageConfigState, (state) => state.binListConfig);
    static readonly binScanDialogConfig = createSelector(
        PageConfigState,
        (state) => state.binScanDialogConfig
    );
    static readonly assignDialogConfig = createSelector(
        PageConfigState,
        (state) => state.assignDialogConfig
    );
    static readonly productFormConfig = createSelector(
        PageConfigState,
        (state) => state.productFormConfig
    );
    static readonly productListConfig = createSelector(
        PageConfigState,
        (state) => state.productListConfig
    );
}
