import { TableColumn } from '@app/core/interfaces/dynamic-table';
import { concatSlash } from '../../../core/helper/text';
import { FormConfig } from '../../../core/interfaces/form-config/basic-form';
import { CommonListConfig } from '../page-configs.helper';

const rootPath = '../../../../assets/json/stock-count';
export const BIN_SEARCH_FORM = concatSlash([
    rootPath,
    'stock-count-bin-search-form.json',
]);
export const BIN_ASSIGN_FORM = concatSlash([
    rootPath,
    'stock-count-bin-assign-form.json',
]);
export const BIN_SCAN_FORM = concatSlash([
    rootPath,
    'stock-count-bin-scan-form.json',
]);
export const BIN_TABLE = concatSlash([rootPath, 'stock-count-bin-table.json']);
export const BIN_MOBILE_TABLE = concatSlash([
    rootPath,
    'stock-count-bin-table-mobile.json',
]);
export const PRODUCT_FORM = concatSlash([rootPath, 'product-create-form.json']);
export const PRODUCT_BIN_FORM = concatSlash([
    rootPath,
    'product-bin-form.json',
]);
export const PRODUCT_SEARCH_FORM = concatSlash([
    rootPath,
    'product-search-form.json',
]);
export const PRODUCT_TABLE = concatSlash([rootPath, 'product-table.json']);

export const ConfigFeatureKey = 'stockCountConfig';

export interface ConfigState {
    binListConfig?: BinListConfig;
    binScanDialogConfig?: FormConfig;
    assignDialogConfig?: FormConfig;
    productListConfig?: ProductListConfig;
    productFormConfig?: FormConfig;
}

export interface ProductListConfig extends CommonListConfig {
    binFormConfig: FormConfig;
}

export interface BinListConfig extends CommonListConfig {
    mobileTableConfig: TableColumn[];
}
