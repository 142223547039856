import { createReducer, on } from '@ngrx/store';
import { ConfigState } from './stock-count-config';
import { StockCountActions } from './stock-count-config.action';

export const reducer = createReducer(
    {} as ConfigState,
    on(StockCountActions.loadBinListConfigSuccess, (state, { data }) => ({
        ...state,
        binListConfig: data,
    })),
    on(
        StockCountActions.loadBinScanDialogConfigsSuccess,
        (state, { data }) => ({
            ...state,
            binScanDialogConfig: data,
        })
    ),
    on(StockCountActions.loadAssignDialogConfigsSuccess, (state, { data }) => ({
        ...state,
        assignDialogConfig: data,
    })),
    on(StockCountActions.loadProductFormConfigsSuccess, (state, { data }) => ({
        ...state,
        productFormConfig: data,
    })),
    on(StockCountActions.loadProductListConfigSuccess, (state, { data }) => ({
        ...state,
        productListConfig: data,
    }))
);
