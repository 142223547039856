import { createAction, props } from '@ngrx/store';
import { FormConfig } from '../../../core/interfaces/form-config/basic-form';
import { CommonListConfig } from '../page-configs.helper';
import { BinListConfig, ProductListConfig } from './stock-count-config';

export class StockCountActions {
    // Stock Counting List Configuration Actions
    static readonly loadBinListConfig = createAction(
        '[Stock Count List Config] Loading'
    );
    static readonly loadBinListConfigSuccess = createAction(
        '[Stock Count List Config] Success',
        props<{ data: BinListConfig }>()
    );

    static readonly loadBinScanDialogConfigs = createAction(
        '[Bin Scan Dialog Config] Loading'
    );
    static readonly loadBinScanDialogConfigsSuccess = createAction(
        '[Bin Scan Dialog Config] Success',
        props<{ data: FormConfig }>()
    );

    static readonly loadAssignDialogConfigs = createAction(
        '[Assign Dialog Config] Loading'
    );
    static readonly loadAssignDialogConfigsSuccess = createAction(
        '[Assign Dialog Config] Success',
        props<{ data: FormConfig }>()
    );

    static readonly loadProductFormConfigs = createAction(
        '[Product Form Config] Loading'
    );
    static readonly loadProductFormConfigsSuccess = createAction(
        '[Product Form Config] Success',
        props<{ data: FormConfig }>()
    );

    static readonly loadProductListConfigs = createAction(
        '[Product List] Loading'
    );
    static readonly loadProductListConfigSuccess = createAction(
        '[Product List] Success',
        props<{ data: ProductListConfig }>()
    );
}
